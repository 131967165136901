import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Bootstrap from '../components/bootsrap';

import './blogTemplate.scss';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  return (
    <Bootstrap>
      <Helmet>
        <title>{frontmatter.title} | Maneesh Chiba</title>
      </Helmet>
      <article className={'post'}>
        <time dateTime={frontmatter.rawDate}>{frontmatter.date}</time>
        <h1 className={'post-title'}>{frontmatter.title}</h1>
        {frontmatter.subtitle ? (<h2 className={'post-subtitle'}>{frontmatter.subtitle}</h2>) : null}
        <section className="post-content" dangerouslySetInnerHTML={{ __html: html }} />
      </article>
    </Bootstrap>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        rawDate: date
        date(formatString: "MMMM DD, YYYY")
        path
        title
        subtitle
      }
    }
  }
`;
